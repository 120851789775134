.container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 350px));
    justify-content: space-evenly;
    gap: 64px;
}

.post {
    display: flex;
    flex-direction: column;
    text-decoration: none;

    .thumbnail {
        width: 100%;
        height: 220px;
        object-fit: cover;
    }

    .title {
        font-size: 1.5rem;
        font-weight: 500;
        color: #000;
        margin: 20px 0;
    }

    .publicationDate {
        color: #000000;
        font-size: 0.875rem;
        font-weight: 300;
    }

    &:hover {
        cursor: pointer;

        .title {
            color: #066d69;
        }
    }
}