// Variables
$default-color: rgb(124, 124, 124);
$ellipsis-color: rgb(124, 124, 124);

// Default loading
.defaultContainer {
    display: flex;
    justify-content: center;
    width: 100%;
}

.loadingDefault {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.loadingDefault div {
    position: absolute;
    width: 6px;
    height: 6px;
    background: $default-color;
    border-radius: 50%;
    animation: loading-default 1.2s linear infinite;
}
.loadingDefault div:nth-child(1) {
    animation-delay: 0s;
    top: 37px;
    left: 66px;
}
.loadingDefault div:nth-child(2) {
    animation-delay: -0.1s;
    top: 22px;
    left: 62px;
}
.loadingDefault div:nth-child(3) {
    animation-delay: -0.2s;
    top: 11px;
    left: 52px;
}
.loadingDefault div:nth-child(4) {
    animation-delay: -0.3s;
    top: 7px;
    left: 37px;
}
.loadingDefault div:nth-child(5) {
    animation-delay: -0.4s;
    top: 11px;
    left: 22px;
}
.loadingDefault div:nth-child(6) {
    animation-delay: -0.5s;
    top: 22px;
    left: 11px;
}
.loadingDefault div:nth-child(7) {
    animation-delay: -0.6s;
    top: 37px;
    left: 7px;
}
.loadingDefault div:nth-child(8) {
    animation-delay: -0.7s;
    top: 52px;
    left: 11px;
}
.loadingDefault div:nth-child(9) {
    animation-delay: -0.8s;
    top: 62px;
    left: 22px;
}
.loadingDefault div:nth-child(10) {
    animation-delay: -0.9s;
    top: 66px;
    left: 37px;
}
.loadingDefault div:nth-child(11) {
    animation-delay: -1s;
    top: 62px;
    left: 52px;
}
.loadingDefault div:nth-child(12) {
    animation-delay: -1.1s;
    top: 52px;
    left: 62px;
}
@keyframes loading-default {
    0%, 20%, 80%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.5);
    }
}

// Ellipsis loading
.loadingEllipsis {
    margin: 0 16px;
    display: inline-block;
    position: relative;
    width: 80px;
    height: 40px;
}
.loadingEllipsis div {
    position: absolute;
    top: 13px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: $ellipsis-color;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.loadingEllipsis div:nth-child(1) {
    left: 8px;
    animation: loading-ellipsis1 0.6s infinite;
}
.loadingEllipsis div:nth-child(2) {
    left: 8px;
    animation: loading-ellipsis2 0.6s infinite;
}
.loadingEllipsis div:nth-child(3) {
    left: 32px;
    animation: loading-ellipsis2 0.6s infinite;
}
.loadingEllipsis div:nth-child(4) {
    left: 56px;
    animation: loading-ellipsis3 0.6s infinite;
}
@keyframes loading-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes loading-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes loading-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}
