.knowUsGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .content {
        .title {
            color: #066d69;
            font-size: 2.25rem;
            font-weight: 500;
        }
    }

    .logoContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;

        .sphere {
            position: absolute;
            z-index: -1000;
            width: 270px;
            height: 270px;
            border-radius: 50%;
            background-color: #f9f9f9;
            // background-color: red;
        }

        .logo {
            width: 280px;
        }
    }
}

.perspectivesContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 50px 0;

    .perspective {
        padding: 0 40px;

        .subtitle {
            margin: 0 0 10px;
            text-align: center;
            font-weight: 500;
            color: #066d69;
        }
    }
}

.textContainer {
    margin-top: 50px;
}

.center {
    text-align: center;
}