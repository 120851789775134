.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 100vw;
    overflow-x: hidden;
}

.header {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 20px 100px;



    .logo {
        width: 130px;
        height: 90px;
        object-fit: contain;
    }
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    width: 100%;
}

.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 0;
    background: linear-gradient(to left, #14947f, #066d69);
    padding: 50px 100px;

    .logoOutline {
        width: 130px;
        height: 90px;
        object-fit: contain;
        margin-bottom: 8px;
    }

    .contactText {
        color: #fff;
        font-weight: 300;
        font-size: 0.875rem;
    }

    .socialIcons {
        display: flex;
        gap: 10px;

        .icon {
            width: 24px;
            height: 24px;
            object-fit: contain;
        }
    }
}