// Header
.headerGrid {
    display: grid;
    grid-template-columns: 40% 60%;
}

.headerTitle {
    color: #0d4a48;
    font-size: 2.25rem;
    font-weight: normal;
    margin: 16px 0;
}

.headerDescription {
    color: #2c3f4a;
    font-weight: 300;
    line-height: 1.3;
    font-size: 1.125rem;
}

// Differentials
.differentialsContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 48px;
    row-gap: 32px;
    padding-top: 40px;
}

.differentialCard {
    display: flex;
    align-items: center;
    color: #999;

    .differentialIconContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 68px;
        height: 68px;
        border-radius: 50%;
        background-color: #fff;
        flex-shrink: 0;
        font-size: 1.75rem;
        margin-right: 16px;
    }

    .differentialDescription {
        color: #999;
        font-weight: normal;
    }

    &:hover {
        color: #066d69;

        .differentialDescription {
            color: #066d69;
        }
    }
}

// Motivation
.motivationsSession {
    background-color: #fff;

    .motivationTitle {
        font-size: 2.25rem;
        color: #2c3f4a;
        font-weight: 500;
    }
}

// Form
$formGradient: linear-gradient(63.17deg, rgba(29, 174, 141, 0.9) 3.91%, rgba(6, 109, 105, 0.94) 66.62%);

.formSession {
    background: $formGradient, url(../../assets/images/baggages.png); 
}

.formContainer {
    display: grid;
    grid-template-columns: 1fr min-content 1fr;
    column-gap: 150px;
    grid-template-areas: 
        "title . ."
        "timeline icon form";
    grid-auto-flow: column;
}

.formTitle {
    color: #fff;
    font-weight: 500;
    margin: 0 0 40px;
    grid-area: title;
}

.timelineList {
    grid-area: timeline;
    list-style: none;
    padding: 0;
    margin: 0;

    .timelineEvent {
        padding-bottom: 40px;
        position: relative;
        padding-left: 30px;
        margin-left: 15px;
        border-left: 2px solid #8ffae1;

        &:last-child {
            padding-bottom: 0;
            border: none;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left:-8px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #8ffae1;
        }
    }
}

.stepTitle {
    font-size: 1.125rem;
    font-weight: 500;
    margin: 0;
    line-height: 1.3;
    color: #fff;
}

.stepDescription {
    margin: 0;
    color: #ddfff7;
    font-weight: normal;
}

.formIcon {
    grid-area: icon;
    color: #8ffae1;
    font-size: 1.5rem;
    align-self: center;
}

.form {
    grid-area: form;

    .formLabel {
        color: #fff;
        font-weight: 300;
    }

    .formInterestsGrid {
        display: grid;
        grid-template-columns: 50% 50%;
        gap: 16px;
        padding: 8px 0;
    }

    .formCountryGrid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 16px;
        padding: 8px 0;
    }

    .submitButton {
        width: auto;
        background-color: #185a4b;
        border-radius: 42px;
        font-weight: 300;
        padding: 16px 60px;
        margin-top: 24px;
        box-shadow: -4px 6px 8px 0 rgba(0, 0, 0, 0.14);
    }
}

// Franchise
.franchiseSession {
    margin-top: 120px;
    margin-bottom: 120px;
    float: left;
}

.franchiseContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;

    h1 {
        font-size: 2rem;
        color: #2c3f4a;
        font-weight: normal;
        margin: 0 0 12px;
    }

    p {
        margin: 12px 0;
        font-size: 1.25rem;
        color: #2c3f4a;
    }
}

.center {
    display: flex;
    justify-content: center;
    margin-top: 40px;
}

.knowMore {
    display: inline;
    color: #2c3f4a;
    margin: 24px 0 0;
    padding: 16px 0;
    font-weight: 300;
    
    .arrowIcon {
        margin-right: 8px;
    }
}