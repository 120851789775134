.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.post {
    max-width: 780px;
    width: 100%;
}

.thumbnail {
    max-width: 100%;
    width: 100%;
    max-height: 380px;
    height: 380px;
    object-fit: cover;
}

.header {
    margin-bottom: 24px;
    
    .title {
        font-size: 2rem;
        color: #2c3f4a;
        font-weight: normal;
        margin: 18px 0;
    }

    .publicationDate {
        color: #000000;
        font-size: 0.875rem;
        font-weight: 300;
    }
}

.content {
    margin-bottom: 40px;
}

.backButton {
    color: #2c3f4a;
    font-weight: 300;
    font-size: 1.125rem;
    border: none;
    background-color: transparent;

    &:focus {
        outline: 0;
    }

    .icon {
        margin-right: 12px;
    }
}

.socialContainer {
    margin-top: 64px;

    .shareTitle {
        font-size: 1.25rem;
        margin-bottom: 16px;
    }
}

.socialButton {
    margin: 0 8px;
    font-size: 1.5rem;
    border: none;
    background-color: transparent;

    &:focus {
        outline: 0;
    }

    & > .whatsappIcon {
        color: #128C7E;
    }

    & > .facebookIcon {
        color: #4267B2;
    }

    & > .twitterIcon {
        color: #1DA1F2;
    }
}