.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #3b2424;
    padding: 16px;
}

.icon {
    font-size: 2.5rem;
}

.text {
    font-size: 1.25rem;
    font-weight: normal;
}

.inlineIcon {
    display: inline-block;
    font-size: 2rem;
    color: #3b2424;
    padding: 0 24px;
}
