.imageContainer {
    max-width: 50%;
    width: 50%;
    position: relative;
    margin-right: 80px;

    .image {
        width: 80%;
        height: 100%;
        object-fit: cover;
    }
    
    .description {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        color: #2c3f4a;
        background-color: #fff;
        padding: 56px 48px;
        font-size: 2rem;
        max-width: 300px;
    }
}
