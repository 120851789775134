.container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 80px 100px;
    align-items: start;
    justify-items: center;
}

.rightButton {
    display: flex;
    justify-content: flex-end;
    margin-top: 60px;
}

.backgroundContainer {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .background {
        position: absolute;
        top: 0;
        left: 0;

        width: 120%;
        object-fit: cover;
    }
}
