.background {
    position: relative;
    width: 100%;
    height: 100%;

    .backgroundImage {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-bottom-left-radius: 42px;
    }

    .gradient {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(7, 108, 104, 0.81));
        border-bottom-left-radius: 42px;
    }
}

.headerGrid {
    display: grid;
    grid-template-columns: 7fr 9fr;
    gap: 100px;
    margin-top: 120px;
}

.header {
    .title {
        font-size: 2.25rem;
        font-weight: 500;
        color: #066d69;
        margin: 0 0 8px;
    }

    .description {
        margin: 8px 0;
    }
}

.countryCard {
    margin: 40px 0 80px;
    position: relative;
    background-color: #ffffff;
    border-radius: 12px;
    padding: 48px 24px 24px;
    box-shadow:
        0 2.1px 4.2px 0 rgba(119, 119, 119, 0.06),
        0 5.8px 11.5px 0 rgba(119, 119, 119, 0.09),
        0 13.9px 27.7px 0 rgba(119, 119, 119, 0.11),
        0 46px 92px 0 rgba(119, 119, 119, 0.17);
    
    .countryIcon {
        position: absolute;
        top: 0;
        left: 24px;
        transform: translateY(-50%);
        width: 72px;
        height: 72px;
        object-fit: cover;
        border-radius: 50%;
    }
}

.seeMore {
    display: inline-flex;
    align-items: center;
    color: #000;
    font-weight: 500;

    .seeMoreIcon {
        margin-left: 8px;
    }
}

.subtitle {
    font-size: 1.75rem;
    font-weight: 300;
    margin: 16px 0;
    color: #066d69;
}

.detailsGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 40px 0;

    & > *:not(:first-child) {
        padding-left: 48px;
    }
    
    & > *:not(:last-child) {
        padding-right: 48px;
        position: relative;

        &::after {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            content: '';
            width: 1px;
            height: 24px;
            background-color: #ababab;
        }
    }
}

.programJumbotron {
    background-color: #fff;
}

.programGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0 80px;
}

.includedItensHeader {
    font-size: 2rem;
    color: #066d69;
    font-weight: normal;
    margin: 0 0 16px;
}

.includedItens {

    ul {
        list-style: none;
        margin-top: 32px;
        margin-left: 16px;
        
        li, lo {
            color: #066d69;
            font-weight: 300;
            margin: 12px 0;
            font-weight: 400;
            
            &::before {
                content: "\2022";
                font-weight: bold;
                display: inline-block;
                width: 1em;
                margin-left: -1em;
            }
        }
    }
}

.priceContainer {
    align-self: center;
    justify-self: center;
    width: 100%;
    max-width: 280px;
    border-radius: 8px;
    box-shadow: 0 12px 20px 0 rgba(0, 0, 0, 0.13);
    border: solid 1px #f4f5f6;
    background-color: #ffffff;
    overflow: hidden;
}

.priceHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #066d69;
    padding: 24px 32px;
}

.installment {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    .installmentQuantity {
        color: #fff;
        margin-right: 8px;
    }

    .installmentValue {
        color: #fff;
        font-size: 3rem;
    }
}

.priceCash {
    color: #fff;
}

.priceBody {
    display: flex;
    flex-direction: column;
    padding: 24px;
    min-height: 280px;

    .priceDescription {
        margin: 0;
        font-size: 0.75rem;
        line-height: 1.4;
        text-align: center;
        flex-grow: 1;
    }

    .buyButton {
        padding: 16px;
        margin-top: 8px;
    }
}