.select {
    min-width: 150px;
    background-color: transparent;
    border: solid #8ffae1;
    border-width: 0 0 1px;
    padding: 8px;
    color: #fff;
    font-weight: 300;
    outline: none;
}

.option {
    background-color: #185a4b;
}