.container {
    display: grid;
    grid-template-rows: 50% 50%;
    background-color: #fff;
    border-radius: 12px;
    max-width: 350px;
    width: 100%;
    height: 100%;
    text-decoration: none;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.content {
    padding: 24px;
}

.header {
    display: flex;
    align-items: center;

    .title {
        flex-grow: 1;
        margin: 0;
        font-size: 1.375rem;
        font-weight: 500;
        color: #000;
    }

    .icon {
        width: 72px;
        height: 72px;
        object-fit: cover;
        border-radius: 50%;
        flex-shrink: 0;
        margin-left: 24px;
    }
}

.description {
    font-weight: 300;
}

.thumbnailContainer {
    position: relative;
    width: 100%;
    height: 100%;
    
    .thumbnail {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0 0 12px 12px;
    }

    .thumbnailCloud {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to top, rgba(255, 255, 255, 0), #ffffff);
    }
}