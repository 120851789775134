.container {
    display: inline-flex;
    align-items: baseline;
    color: #fff;
    min-height: 25px;
    font-weight: 300;
    cursor: pointer;

    input {
        display: none;

        &:checked ~ .text::after {
            display: block;
        }
    }

    .text {
        position: relative;
        padding-left: 35px;
        padding-top: 4px;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 25px;
            width: 25px;
            background-color: #fff;
            border-radius: 4px;
            box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.53);
        }

        &::after {
            content: "";
            position: absolute;
            display: none;
            left: 9px;
            top: 5px;
            width: 5px;
            height: 10px;
            border: solid #1dae8d;
            border-width: 0 3px 3px 0;
            transform: rotate(45deg);
        }
    }
}