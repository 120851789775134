.container {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 100vw;
    overflow-x: hidden;
}

.header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}

.logo {
    margin-left: 120px;
    margin-top: 20px;
}

.contentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    width: 100%;
}