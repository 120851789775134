.countrySession {
    background-color: #fff;
}

.headerContainer {
    position: relative;

    .backgroundImage {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -2;
        filter: brightness(70%);
    }

    .title {
        color: #fff;
        font-size: 3rem;
        font-weight: normal;
        text-align: center;
        text-shadow: 1px 1px 10px rgba($color: #000, $alpha: 0.3);
        margin-top: 0;
    }
}

.headerGrid {
    display: grid;
    grid-template-columns: 7fr 5fr;
    gap: 30px;

    .videoContainer {
        iframe {
            width: 100%;
            min-height: 450px;
        }

        .paymentText {
            color: #fff;
            font-weight: normal;
            text-shadow: 1px 1px 10px rgba($color: #000, $alpha: 0.3);
        }
    }

    .form {
        background-color: #f4f5f6;
        padding: 40px;
        border-radius: 20px;
        place-self: center;
        min-width: 300px;

        .recaptcha {
            padding: 16px 0;
        }

        .submitButton {
            margin-top: 16px;
        }
    }
}



.includedItens {

    ul {
        list-style: none;
        margin-top: 32px;
        margin-left: 16px;
        
        li, lo {
            color: #066d69;
            font-weight: 300;
            margin: 12px 0;
            font-weight: 400;
            
            &::before {
                content: "\2022";
                font-weight: bold;
                display: inline-block;
                width: 1em;
                margin-left: -1em;
            }
        }
    }
}