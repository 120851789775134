.tabBar {
    display: flex;

    .tabName {
        margin: 0 12px;
        padding: 10px 40px;
        color: #999999;
        border-bottom: 2px solid #f4f5f6;
        font-weight: 300;
        border-radius: 0;
        background-color: transparent;

        &.selected {
            color: #2c3f4a;
            border-bottom: 2px solid #1dae8d;
        }
    }
}

.tabContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 60px 40px;
    padding: 40px;
}

.motivationContainer {
    display: flex;
    align-items: baseline;

    .motivationIcon {
        margin: 0 8px;
        color: orange;
    }

    .motivationText > p {
        color: #2c3f4a;
        font-size: 1.125rem;
        line-height: 1.5rem;
        font-weight: 300;
        margin: 0;

        &:hover {
            color: #1dae8d;
        }
    }
}