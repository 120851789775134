.topbar {
    display: flex;
    align-items: baseline;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1000;

    background-image: linear-gradient(to top, #07958f, #066d69);
    padding: 24px 100px 12px 24px;
    border-radius: 0 0 0 24px;
}

.item {
    margin: 16px 20px;
    font-size: 1.5rem;
    font-weight: 200;
    color: #ffffff;
    text-decoration: none;
}

.backgroundPage {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1000;

    height: 520px;
    width: 100%;
}