.container {
    display: grid;
    grid-template-columns: 2fr 1fr;
}

.sidebar {
    margin-left: 50px;
}

.section {
    margin-bottom: 40px;

    .title {
        margin: 0 0 16px;
        padding-bottom: 16px;
        border-bottom: #1dae8d solid 2px;

        font-size: 2rem;
        color: #2c3f4a;
        font-weight: 500;
    }

    .list {
        list-style: none;
        padding-left: 32px;
        line-height: 1.6;

        .item {
            color: #000;

            &::before {
                content: "\2022";
                color: #1dae8d;
                font-weight: bold;
                display: inline-block;
                width: 1em;
                margin-left: -1em;
            }

            &:hover {
                color: #666;
            }
        }
    }

    .otherTitles {
        display: block;
        font-size: 1.25rem;
        line-height: 1.3;
        margin: 15px 0;
        text-decoration: none;
        color: #000;
        letter-spacing: 0.1px;

        

        &:hover {
            cursor: pointer;
            color: #066d69;
        }
    }

}
