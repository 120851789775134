.jumbotron {
    background-color: #fff;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    width: 100%;
}

$duration: 400ms;

.enter.testimony {
    opacity: 0;
    transform: translateX(100%);
}

.enterActive.testimony {
    opacity: 1;
    transform: translateX(0%);
    transition: opacity $duration ease, transform $duration ease;
}

.exit.testimony {
    opacity: 1;
    transform: translateX(0%);
}

.exitActive.testimony {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity $duration ease, transform $duration ease;
}

.testimony {
    display: flex;
    align-items: center;
    max-width: 740px;
    width: 100%;
    height: 240px;
    color: black;

    .imageContainer {
        position: relative;
        margin-right: 40px;

        .profileImage {
            width: 136px;
            height: 136px;
            object-fit: cover;
            border-radius: 50%;
        }

        .quote {
            position: absolute;
            top: 0;
            right: 0;
        }
    }

    .testimonyText {
        display: block;
        display: -webkit-box;
        max-height: 240px; // font-size*line-height*line-clamp
        font-size: 1.25rem;
        line-height: 1.2;
        -webkit-line-clamp: 10;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.pageButtonsContainer {
    margin-top: 40px;

    .pageButton {
        margin: 0 8px;
        padding: 0;
        width: 10px;
        height: 10px;
        border: solid 1px #066d69;
        border-radius: 50%;
        background-color: transparent;

        &.active {
            background-color: #066d69;
        }
    }
}