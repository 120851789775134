// TODO: Revisar CSS padrão do Create React App



// Imports
@import-normalize;
@import './mixins';

/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

$fonts: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI',
'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
'Helvetica Neue', sans-serif;

/**
* Default CSS from Create React App
*/
body {
    margin: 0;
    font-family: $fonts;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f4f5f6;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/**
* Custom SASS
*/
html, body, #root {
    width: 100%;
    max-width: 100vw;
    height: 100%;
    max-height: 100vh;
}

div, input, textarea, header, footer, section {
    box-sizing: border-box;
}

p, span {
    color: #000;
    font-weight: 300;
    line-height: 1.3;
}

select, button {
    font-family: $fonts;
}

button {
    font-size: 1rem;
    color: #fff;
    border-radius: 50px;
    border: none;
    padding: 8px 24px;
    cursor: pointer;

    &:focus {
        outline: none;
    }
}

textarea:focus, input:focus {
    outline: none;
}

a {
    text-decoration: none;
}