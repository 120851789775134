.grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.locationContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 56px;
}

.location {
    display: flex;
    color: #066d69;

    .icon {
        margin-right: 8px;
        font-size: 1.25rem;
    }
}

.infoContainer {
    .title {
        font-size: 1.25rem;
        font-weight: 500;
        margin: 0;
    }

    p {
        color: #066d69;
        margin: 8px 0;
    }
}

.form {
    padding-left: 128px;
}

.formGroup {
    margin-bottom: 16px;
}