.container {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    overflow: visible;
    margin-left: 150px;
    height: 400px;
    max-height: 400px;
}

.programOffset {
    position: absolute;
    height: 100%;
    width: 200px;
}

.cardContainer {
    min-width: 300px;
    width: 350px;
    padding: 0 20px;
}

.pageButtons {
    position: absolute;
    top: 100%;
    left: 400px;
    transform: translateY(-50%);

    & > * {
        border-radius: 50%;
        font-size: 1.5rem;
        margin: 0 8px;
        padding: 0;
        box-shadow: -5px 12px 20px 0 rgba(0, 0, 0, 0.1);
    }

    .prevButton {
        background-color: #fff;
        color: #1dae8d;
        width: 50px;
        height: 50px;
    }

    .nextButton {
        background-image: linear-gradient(to top, #1dae8d, #066d69);
        color: #fff;
        width: 70px;
        height: 70px;
    }
}