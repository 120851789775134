.submitButton {
    display: flex;
    align-items: center;
    font-size: 1.125rem;
    text-align: center;
    line-height: 1.32;
    color: #fff;
    background-color: #066d69;
    border-radius: 4px;
    font-weight: normal;
    border: none;
    width: 100%;
    padding: 14px 24px;
}

.loaderContainer {
    margin-left: 8px;
}

.loader {
    border: 3px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #3498db;
    width: 20px;
    height: 20px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}