.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 0;

    .icon {
        width: 72px;
        height: 72px;
        object-fit: cover;
        border-radius: 50%;
        margin-bottom: 20px;
    }

    .moreAbout {
        font-weight: 500;
    }

    .countryName {
        font-size: 2.5rem;
        font-weight: 500;
        color: #066d69;
        margin: 5px 0;
    }
}

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin: 0 20px;
}

.title {
    font-size: 1.5rem;
    color: #066d69;
    font-weight: normal;
}

.infoWrapper {
    display: grid;
    grid-template-columns: auto auto;
    justify-items: flex-start;
    align-content: flex-start;
    justify-content: space-evenly;
}

.infoContainer {
    display: inline-flex;
    padding: 16px;

    .icon {
        color: #066d69;
        margin-right: 8px;
        width: 20px;
    }

    .propertyName {
        color: #066d69;
        font-weight: 500;
        margin-bottom: 4px;
    }
}

.gallery {
    margin-top: 60px;
}