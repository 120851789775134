.franchiseSession {
    margin-bottom: 48px;
    float: left;
}

.title {
    color: #2c3f4a;
    font-size: 2rem;
    font-weight: normal;
}
