@import '../../../../styles/mixins';

.container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 32px;

    padding: 50px 120px;
    background-image: linear-gradient(to left, #14947f, #066d69);
}

.column {
    display: flex;
    color: #ffffff;

    .columnIcon {
        width: 20px;
        height: 20px;
        object-fit: contain;
        margin-right: 8px;
    }

    .columnContent {
        width: 100%;
    }

    .columnTitle {
        font-size: 1.125rem;
        font-weight: normal;
        margin: 0 0 16px;
    }
}

.linkList {
    list-style: none;
    padding: 0;
    margin: 0;

    .linkItem {
        padding-bottom: 8px;
        font-weight: 300;
        font-size: 0.875rem;
        vertical-align: center;

        &:last-child {
            padding-bottom: 0;
        }

        .linkIcon {
            color: #8ffae1;
            margin-right: 8px;
            font-size: 0.625rem;
        }

        a {
            color: #fff;
        }
    }
}

.contactText {
    color: #fff;
    font-weight: 300;
    font-size: 0.875rem;
}

.socialIcons {
    display: flex;
    gap: 10px;

    .icon {
        width: 24px;
        height: 24px;
        object-fit: contain;
    }
}

.logo {
    width: 120px;
}

.copyrights {
    padding: 4px 120px;
    font-size: 0.75rem;
    background-color: #066d69;
    color: #ffffff;
    font-weight: 200;
}
